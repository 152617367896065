<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-select
        :key="`${step.id}-async`"
        v-model="properties.async"
        :items="formattedSmtpTypes"
        :label="$lang.labels.type"
        outlined
        dense
        required
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="smtp-async"
      ></v-select>
    </v-col>

    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="SMTP"
      :properties="properties"
      step-label="smtp"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-from`"
        v-model="properties.from"
        outlined
        dense
        :label="$lang.labels.from"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || checkEmail(v) || 'Wrong format']"
        :readonly="!canEdit"
        data-cy="smtp-from"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-to`"
        v-model="properties.to"
        outlined
        dense
        :label="$lang.labels.to"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || checkEmail(v) || 'Wrong format']"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="smtp-to"
      ></v-text-field>
    </v-col>

    <v-col cols="12" class="py-1">
      <add-vals
        v-if="showCc"
        :key="`${step.id}-cc`"
        type="cc"
        step-type="SMTP"
        :can-edit="canEdit"
        :data="properties.cc"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || checkEmail(v) || 'Wrong format']"
        @dataChanged="handleChange('cc', $event)"
        @hide="showCc = false; properties.cc = []"
      ></add-vals>
      <p v-else class="pl-1 cursor-pointer color-primary" @click="showCc = true">{{ $lang.actions.addCc }}</p>
    </v-col>

    <v-col cols="12" class="py-1">
      <add-vals
        v-if="showBcc"
        :key="`${step.id}-bcc`"
        type="bcc"
        step-type="SMTP"
        :can-edit="canEdit"
        :data="properties.bcc"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || checkEmail(v) || 'Wrong format']"
        @dataChanged="handleChange('bcc', $event)"
        @hide="showBcc = false; properties.bcc = []"
      ></add-vals>
      <p v-else class="pl-1 cursor-pointer color-primary" @click="showBcc = true">{{ $lang.actions.addBcc }}</p>
    </v-col>

    <text-templates-select
      :key="`${step.id}-textTemplate`"
      step-type="SMTP"
      :properties="properties"
      step-label="smtp"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('textTemplate', $event)"
    />

    <v-col cols="12" class="pb-1">
      <add-key-value
        :key="`${step.id}-variables`"
        type="variables"
        step-type="EMAIL"
        :can-edit="canEdit"
        :data="properties.variables"
        :single-step="step"
        :text-template-name="properties.textTemplate"
        @editor="$emit('openEditor', $event, 'variables')"
        @dataChanged="handleChange('variables', $event)"
      />
    </v-col>
    <v-col cols="12" class="py-1">
      <add-attachments
        :key="`${step.id}-attachments`"
        type="attachments"
        step-type="EMAIL"
        :can-edit="canEdit"
        :data="properties.attachments"
        @dataChanged="handleChange('attachments', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, TextTemplatesSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import AddAttachments from '../Attachments'
import AddVals from '../ValList'
import StepMixin from './StepMixin'

import { EMAIL_TYPES } from './constants'
import { checkEmail } from '@/utils/helpers'

export default {
  name: 'EmailStep',
  components: {
    CredentialsSelect,
    TextTemplatesSelect,
    AddKeyValue,
    AddAttachments,
    AddVals
  },
  mixins: [StepMixin],
  data() {
    return {
      showCc: false,
      showBcc: false
    }
  },
  computed: {
    formattedSmtpTypes() {
      return EMAIL_TYPES.map((x) => {
        return { text: this.$lang.status[x], value: x === 'ASYNC' }
      })
    }
  },
  created() {
    if (!this.properties.variables) this.properties.variables = {}
    if (!this.properties.variables.subject) this.properties.variables.subject = ''
    if (this.properties.locale) {
      this.properties.variables.locale = this.properties.locale
    }
    if (!this.properties.attachments) this.properties.attachments = []
    if (this.properties.cc && this.properties.cc.length > 0) {
      this.showCc = true
    }
    if (this.properties.bcc && this.properties.bcc.length > 0) {
      this.showBcc = true
    }
    if (![true, false].includes(this.properties.async)) {
      this.properties.async = true
    }
  },
  methods: {
    checkEmail
  }
}
</script>
